.about-container {
  .banner-section {
    position: relative;
    .banner-image {
      max-height: 700px;
      object-fit: cover;
      max-width: 100%;
      height: auto;
      width: 100%;
    }
    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Inter", sans-serif;
      width: 75%;
      font-weight: 900;
      font-size: 104px;
      text-align: center;
      color: #ffffff;

      text-shadow: 0 0 3px #161414, 0 0 5px #0e0e1b;
    }
  }
  .about-body {
    .web-view {
      @media screen and (max-width: 992px) {
        display: none;
      }
      .about-cards {
        .about-card-heading {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          text-transform: capitalize;
          color: #0cc0ff;
        }
        .card-content {
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0%, 30%);
          @media screen and (max-width: 992px) {
            position: unset;
            text-align: center;
          }
        }
        .card-img {
          img {
            height: 650px;
            width: 100%;
            clip-path: polygon(
              50% 0,
              100% 25%,
              100% 75%,
              50% 100%,
              0 75%,
              0 25%
            );
          }
          @media screen and (max-width: 992px) {
            display: none;
          }
        }
      }
    }
    .app-view {
      @media screen and (min-width: 992px) {
        display: none;
      }
      .about-cards {
        text-align: center;
        .about-card-heading {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          text-transform: capitalize;
          color: #0cc0ff;
        }
      }
    }
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }

  .banner-cards {
    position: relative;
    bottom: 115px;
    .banner-card {
      text-align: center;
      background: white;
      border: 1px solid #22c6ff;
      border-radius: 12px;
      padding: 19px;
      height: 398px;
      .heading {
        color: #42cdff;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        padding-top: 8px;
      }
      .body {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #505050;
        margin: 0;
        text-align: justify;
        @media screen and (max-width: 992px) {
          font-size: 20px !important;
        }
      }
    }
  }
}
