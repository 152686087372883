.news-letter-container {
  .banner-section {
    position: relative;
    .banner-image {
      //   max-height: 700px;
      object-fit: cover;
      max-width: 100%;
      height: auto;
      width: 100%;
    }
    .banner-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Inter", sans-serif;
      width: 75%;
      font-weight: 900;
      font-size: 104px;
      text-align: center;
      color: #ffffff;

      text-shadow: 0 0 3px #161414, 0 0 5px #0e0e1b;
    }
  }
  .news-letter-body {
    text-align: center;
    .news-letter-content {
      padding-top: 50px;
    }

    .news-letters {
      padding-top: 30px;
      a {
        text-decoration: none;
      }
      .news-letter-card {
        box-shadow: 2px 2px 5px 6px rgb(0 0 0 / 16%);
        background-color: #ffffff;
        padding: 15px;
        border-radius: 30px;
        .news-letter-title {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          text-transform: capitalize;
          color: #0cc0ff;
        }
        .news-letter-card-img {
          height: 375px;
        }
        .news-letter-card-content {
          padding: 15px;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 25px;
          color: #505050;
          line-height: 30px;
        }
      }
    }
  }
}
