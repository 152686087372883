.game-container {
  position: absolute;
  top: 0;
  z-index: 999999;
  .loading-overlay {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(50%, 50%);
    height: 100vh !important;
    width: 100%;
    background: #000000;
    // background: #37caffcf;
    text-align: center;
    img {
      // width: 100% !important;
      // position: absolute;
      transform: translate(0, 100%);
      height: 256px !important;
      width: 256px !important;
      display: inline-block;
      vertical-align: middle;
    }
    .custom-loading-progess {
      // background-color: green;
    }
    // .puff-loading {
    //   position: relative;
    //   svg {
    //     position: absolute;
    //   }
    // }
  }
  .btnHome {
    position: absolute;
    top: 10px;
    left: 10px;
    background: linear-gradient(275.03deg, #53d2ff 9.24%, #0cc0ff 95.67%);
    box-shadow: 0px 2.39137px 3.58706px rgb(165 159 159 / 25%);
    border-radius: 4.69199px;
    color: #fff !important;
  }
}

#unity-container {
  input {
    // position: unset !important;
    // top: 0 !important;
    // left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: auto !important;
    height: auto !important;
    font-size: 20px !important;
    outline-width: 1px !important;
    opacity: 1 !important;
    resize: none !important;
    padding: 0px 1px !important;
    cursor: default !important;
    touch-action: unset !important;
  }
}
