// import pages
@import url("./pages/home.scss");
@import url("./pages/footer.scss");
@import url("./pages/contact.scss");
@import url("./pages/blog.scss");
@import url("./pages/platform.scss");
@import url("./pages/news-letter.scss");
@import url("./pages/about.scss");
@import url("./pages/game.scss");
@import url("./pages/header.scss");

textarea {
  resize: none !important;
}

.custom-model-header {
  background: #0d0c0cab;
  .modal-dialog {
    height: 100vh;
    width: 100%;
    transform: translate(0%, 7%) !important;
    max-width: 1200px !important;

    .custom-model-body {
      padding: 0;
      position: relative;
      .model-image-header {
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        .model-body-header {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 0.4);
          padding: 24px 45px;
          color: #fff;
          text-shadow: 0 0 3px #161414, 0 0 5px #0e0e1b;
          text-transform: capitalize;
          font-weight: 900;
          font-size: 104px;
          border: 1px solid #fff;
          font-family: "Inter", sans-serif;
          width: 75%;
        }
      }
    }
  }
}

.custom-model-gitex {
  background: #0d0c0cab;
  .modal-dialog {
    height: 100vh;
    width: 100%;
    transform: translate(0%, 7%) !important;
    max-width: 1200px !important;
    .custom-model-body {
      padding: 0;
      position: relative;
      .model-image-gitex {
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        .model-body-gitex {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          text-align: center;
          background-color: #3e3b6917;
          padding: 40px 0;
          color: #fff;
          text-shadow: 0 0 3px #161414, 0 0 5px #0e0e1b;
          border: 1px solid #fff;

          h5 {
            text-transform: capitalize;
            font-weight: 900;
            font-size: 70px;
            font-family: "Inter", sans-serif;
            @media screen and (max-width: 992px) {
              font-size: 20px !important;
            }
          }
          input {
            @media screen and (max-width: 992px) {
              margin-bottom: 12px !important;
            }
          }
          button {
            @media screen and (max-width: 992px) {
              font-size: 12px !important;
              width: 130px !important;
            }
          }
          .model-gitex-content {
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 31px;
            text-align: center;
            color: #ffffff;
            @media screen and (max-width: 992px) {
              font-size: 12px !important;
            }
          }
          .model-gitex-btn {
            text-transform: uppercase;
            background: #0da6ff;
            border: 1px solid #ffffff;
            font-style: normal;
            font-weight: 500;
            font-size: 18.1424px;
            line-height: 22px;
            color: #ffffff;
            padding: 12px 25px;
            margin-top: 8px;
          }
        }
      }
    }
  }
  .model-close-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 99;
    color: #fff;
    background-color: #f50b0b;
    padding: 12px 18px;
    border-radius: 25px;
  }
}

.bottom-85 {
  bottom: 85px !important;
}

a {
  color: #767676 !important;
  &:hover {
    // color: #767676 !important;
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
// &:focus
