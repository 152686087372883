.contact-container {
  .contact-form {
    textarea {
    }
  }
  .contact-logo {
    img {
      max-width: 20px !important;
    }
  }
}
