.home-container {
  .business_level {
    @media screen and (max-width: 992px) {
      text-align: center;
    }
    img {
      @media screen and (max-width: 992px) {
        margin-top: 100px;
      }
    }
  }
  .contact_us {
    @media screen and (max-width: 992px) {
      text-align: center;
      h3 {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      form {
        input {
          width: 100%;
        }
        .about-input-btn {
          padding-top: 30px;
          width: 100%;
          text-align: center;
        }
      }
    }
    .container {
      .success-message {
        color: #1ac4ff;
        font-size: 25px;
        position: absolute;
        top: 55px;
        left: 20px;
      }
    }
  }
  .trade {
    img {
      @media screen and (max-width: 1550px) {
        display: none;
      }
    }
  }
  .land-nft-section {
  }
  .about_vorbit {
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }
  // .about_vorbit {
  //   @media screen and (max-width: 992px) {
  //     text-align: center;
  //   }
  //   .about-img-container {
  //     @media screen and (max-width: 992px) {
  //       flex-direction: column !important;
  //       text-align: center;
  //       div {
  //         width: 100% !important;
  //         max-width: 100% !important;
  //       }
  //     }
  //   }
  // }
}
