.blog-detail-container{
    .blot-header{

    }
    .blog-footer{
       .author-detail-container{
            display: flex;
            justify-content: center;
        .author-detail{
            display: flex;
            border-radius: 7px;
            box-shadow: 0 0 5px 0 rgb(0 0 0 / 12%);
            background-color: #ffffff;
            border: #26a9e1 1px solid;
            height: 200px;
            padding: 15px;
            width: 80%;
                .author-card-body{
                    margin-left: 15px;
                    position: relative;
                    .heading{
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 35px;
                        color: #4B9ADF;
                        text-transform: capitalize;
                    }
                    .author-card-body-buttons{
                        position: absolute;
                        bottom:10px;
                        span{
                            background-color: #26a9e1;
                            color: #ffffff;
                            padding: 8px 18px;
                            border-radius: 20px;
                            margin-left: 10px;
                            cursor: pointer;
                        }
                    }

                }
                .user-image{
                    height: 130px !important;
                    width: 130px !important;
                    background-color: #ffffff;
                    box-shadow: 8px 2px 15px 15px rgb(0 0 0 / 12%);
                    padding: 9px;
                    border-radius: 12px;
                }
        }
    }
        .similar-post{

        }
    }
    .blog-detail-main-image{
        border-radius: 30px 30px 0 0;
    }
}

// 