.top_navbar {
  .partners-links {
    font-family: "Open Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    color: #767676 !important;
    padding: 0px 15px !important;
  }

  // .btn_started {
  //   border-top: none;
  //   border-bottom: none;
  //   border-right: 2px solid red;
  //   border-left: 2px solid red;

  //   // background: linear-gradient(275.03deg, #53d2ff 9.24%, #0cc0ff 95.67%);
  //   box-shadow: 0px 2.39137px 3.58706px rgb(165 159 159 / 25%);
  //   border-radius: 4.69199px;
  //   color: #fff !important;
  //   position: relative;
  //   margin-left: 60px;
  //   // background-color: #273649;
  //   // color: white;
  //   // padding: 14px 28px;
  //   // font-size: 16px;
  //   &::before {
  //     display: block;
  //     content: "";
  //     position: absolute;
  //     width: 91px;
  //     height: 16px;
  //     border-bottom: red 2px solid;
  //     left: 0px;
  //     border-radius: 40%;
  //     bottom: 30px;
  //   }
  //   &::after {
  //     display: block;
  //     content: "";
  //     position: absolute;
  //     width: 91px;
  //     height: 16px;
  //     border-top: red 2px solid;
  //     top: 29px;
  //     left: 0px;
  //     border-radius: 180%;
  //   }
  //   &:hover {
  //     color: #ffffff !important;
  //   }
  // }
  .vorbit-start-btn {
    position: relative;
    width: 140px;
    line-height: 10px;
    border: none !important;
    outline: none !important;
    &:focus {
      border: none !important;
      outline: none !important;
    }

    .btn-oo {
      display: flex;
      justify-content: space-between;
      position: relative;
      bottom: 3px;
      span {
        color: #ffffff;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        // padding: 0px 15px;
      }
    }
    .btn-img {
      position: absolute;
      top: -14px;
      left: 0;
      z-index: -9;
      height: 60px;
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .navbar_links {
    a {
      @media screen and (max-width: 992px) {
        margin: 12px 0px !important;
      }
    }
  }
  .dropdown {
    @media screen and (max-width: 992px) {
      padding: 0 !important;
    }
  }
}
